<template>
  <div>
    <LivePreviewErrors v-if="errors && errors.length" :errors="errors" />
    <TravelAlertsPage
      :headline="headline"
      :introduction="introduction"
      :travelAlertsPanels="panels"
      :page="page"
      :alertsList="alertsList"
    />
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { InitPageParams } from "lib/utils/initPage";
import { FetchError } from "ohmyfetch";
import { LivePreviewPageResponse } from "../../lib/types/Page";
import { getBaseLink } from "~~/lib/utils/link/getBaseLink";
import TravelAlertsPage from "~/components/TravelAlerts/TravelAlertsPage.vue";
import LivePreviewErrors from "~~/components/LivePreview/LivePreviewErrors.vue";
import { TravelAlertsPageProps } from "~~/components/TravelAlerts/Props";
import { initPage } from "~~/lib/utils/initPage";
import { loggerFactory, logTags } from "~~/lib/utils/logger/logger";

const logger = loggerFactory({
  tags: [logTags.Layer.Page, logTags.Page.TravelAlerts],
});

const LivePreviewErrorMessages = {
  PLEASE_WAIT: "Your page will reload in few seconds. Please wait.",
  PLEASE_RELOAD_PAGE:
    "An expected error has occurred with Live Preview. Please save your work and reload this page using the browser.",
};

export default Vue.extend({
  name: "TravelAlerts",
  components: {
    TravelAlertsPage,
    LivePreviewErrors,
  },
  layout: "preview",
  async asyncData({
    params,
    error,
    i18n,
    $config,
    route,
    store,
  }): Promise<LivePreviewPageResponse | undefined> {
    try {
      if (!$config.public.contentstackEnableLivePreview) {
        error({ statusCode: 404 });
        return {};
      }
      const requestParams: InitPageParams = {
        locale_iso: i18n.localeProperties.iso,
        locale_code: i18n.locale,
        locale_domain: $config.public.baseUrl,
        path: route.path,
        slug: getBaseLink(route.path),
        currency_code:
          params.currencyCode || i18n.localeProperties.currencyCode,
        live_preview: route.query?.live_preview ?? undefined,
        content_type_uid: route.query?.content_type_uid ?? undefined,
        current_path: getBaseLink(route.path),
        page_type: "content",
      };

      const pageContent: TravelAlertsPageProps = await $fetch(
        "/api/nuxt/travel-alerts/page",
        {
          params: requestParams,
        }
      );
      await Promise.all(initPage(store, requestParams));

      /**
       * Live Preview bug and message to notify hard reload
       * Bug report case #00020097
       */
      if ((pageContent as unknown as { _in_progress: boolean })._in_progress) {
        return {
          pageContent: undefined,
          errors: [LivePreviewErrorMessages.PLEASE_RELOAD_PAGE],
        };
      }
      return {
        ...pageContent,
        errors: [],
      };
    } catch (e) {
      if ((e as FetchError).name === "FetchError") {
        const fetchError = e as FetchError;
        if (
          fetchError.data.statusCode === 400 &&
          fetchError.data.statusMessage.includes("ValidationError:")
        ) {
          const validationMessage =
            fetchError.data.statusMessage.split("ValidationError:")[1];

          return {
            pageContent: undefined,
            errors: [validationMessage],
          };
        }

        if (
          fetchError.data.statusCode === 500 &&
          fetchError.data.statusMessage.includes("ValidationMessage:")
        ) {
          const validationMessage =
            fetchError.data.statusMessage.split("ValidationMessage:")[1];

          return {
            pageContent: undefined,
            errors: [validationMessage],
          };
        }
      }

      logger.error("asyncData failed in preview/travel-alerts.vue", e);
      if (e instanceof Error) error(e);
      else error({ message: "Something went wrong with the request." });
      return {};
    }
  },
  data() {
    return {
      headline: {},
      introduction: {},
      panels: {},
      page: {},
      alertsList: [],
      errors: [],
    };
  },
  mounted() {
    this.$initLivePreview();
  },
});
</script>
